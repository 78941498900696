<template>
  <div class="join">
    <div class="baseBox">
      <div class="joinBox reset">
        <h1>{{ $t("123") }}</h1>
        <h3>{{ $t("124") }}</h3>
        <div class="buttonWrap">
          <button class="point large" @click="pushLogin">
            {{ $t("btn-login-done") }}
          </button>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "@/components/Footer";

export default {
  components: { Footer },
  methods: {
    pushLogin() {
      this.$router.push(`/${this.$route.params.accountId}/login`);
    },
  },
};
</script>
